<script lang="ts">
	import { LayoutGrid, User } from 'lucide-svelte';
	import HeaderItem from './header-item.svelte';
	import { isAuthenticated } from '$lib/app/persistence';
	import { browser } from '$app/environment';

	let isLoggedIn = browser && isAuthenticated();
</script>

{#if isLoggedIn}
	<HeaderItem href="/@/domains" icon={LayoutGrid} text="Dashboard" />
{:else}
	<HeaderItem href="/auth/login" icon={User} text="Login" />
{/if}
